.box {


    border-radius: 10px;
    box-shadow: 0 1px 4px rgba(18, 22, 33, 0.12);

}

.slider {
    width: 100%;
    height: 6px;
    background-color: #cdd9ed;
}

.slider .thumb {
    width: 23px;
    height: 23px;
    cursor: grab;
    background: #fff;
    border-radius: 50%;
    border: 3px solid #000000;
    position: relative;
    /* Add position relative */
    top: -9px;
    /* Adjust this value to vertically align the thumb */
}