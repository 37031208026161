/* Hide left and right images on mobile */
@media (max-width: 767px) {
    .navbar {
        margin: 0;
        padding-bottom: 0;
    }

    .navbar-brand.d-none.d-lg-block {
        display: none;
    }

    .filter-form {
        margin-top: 0;
        padding-top: 0;
    }

    .navbar-brand.arrow,
    .navbar-brand.logo,
    .navbar-brand.eyes {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .navbar-brand.arrow img,
    .navbar-brand.logo img,
    .navbar-brand.eyes img {
        max-width: 100%;
        height: auto;
    }

    .navbar-brand.logo img {
        max-width: 90%;
        /* Adjust as needed */
    }
}

/* Medium screens (tablets) */
@media (min-width: 768px) and (max-width: 991px) {

    .navbar-brand.arrow,
    .navbar-brand.eyes {
        width: 40%;
    }

    .navbar-brand.logo {
        width: 50%;
    }

    .navbar-brand.arrow img,
    .navbar-brand.eyes img {
        width: 80%;
        height: auto;
    }

    .navbar-brand.logo img {
        width: 100%;
        height: auto;
    }
}

/* Large screens (desktops) */
@media (min-width: 992px) and (max-width: 1300px) {

    .navbar-brand.arrow,
    .navbar-brand.eyes {
        width: 25%;
    }

    .navbar-brand.logo {
        width: 50%;
    }

    .navbar-brand.arrow img,
    .navbar-brand.eyes img {
        width: 100%;
        height: auto;
    }

    .navbar-brand.logo img {
        width: 100%;
        height: auto;
    }
}

/* Extra large screens */
@media (min-width: 1301px) {

    .navbar-brand.arrow,
    .navbar-brand.eyes {
        width: 25%;
    }

    .navbar-brand.logo {
        width: 50%;
    }

    .navbar-brand.arrow img,
    .navbar-brand.eyes img {
        width: 100%;
        height: auto;
    }

    .navbar-brand.logo img {
        width: 100%;
        height: auto;
    }
}