.card {
    display: flex;
    flex-direction: column;
}

.card-img-top {
    height: 200px;
    object-fit: cover;
}

.card-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.card h-100 {
    height: 100%;
}