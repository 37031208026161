/* Ensure the dropdown menu has a fixed height and scrolls when needed */
#filterForm {
    position: -webkit-sticky;
    /* For Safari */
    position: sticky;
    top: 0;
    /* Adjust based on where you want it to stick */
    z-index: 1000;
    /* Ensure it stays above other content */
    background-color: #fff;
    /* Add background color to cover underlying content */
    padding: 1rem;
    /* Add padding to avoid content sticking to edges */
    border-radius: 5px;
    /* Optional: add rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Optional: add shadow for better visibility */
}

.dropdown-menu {
    min-width: 250px;
    max-height: 300px;
    overflow-y: auto;
    padding: 0.5rem;
    margin-left: -4px;
    /* Shift the dropdown 10px to the left */
    left: auto;
    /* Ensure left alignment */
    right: auto;
}


.dropdown-item-container {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    /* Remove extra margin */
    padding: 0;
    /* Remove extra padding */
    justify-content: center;
    /* Center items horizontally */
    align-items: center;
    /* Center items vertically */
}

.dropdown-item {
    width: 50%;
    /* Ensures two items per row */
    box-sizing: border-box;
    /* Makes sure padding and borders are included in the width */
    padding-left: 4px;
    /* Adds 4px padding from the left side */
    display: flex;
    justify-content: flex-start;
    /* Aligns the checkboxes to the left side */
}

.form-check {
    margin: 0;
    padding: 0;
    font-size: 0.9rem;
    display: flex;
    justify-content: flex-start;
    /* Ensures items are aligned to the left */
    align-items: center;
    width: 100%;
    /* Full width to align the checkbox and label */
}


.dropdown-item-custom {
    display: flex;
    align-items: center;
    padding: 0.25rem 0;
    /* Adjust the vertical padding */
    color: rgb(0, 0, 0);
    gap: 0.5rem;
    /* Adds horizontal space between the checkbox and the label */
    width: 100%;
    justify-content: flex-start;
    margin-left: 14px;
    /* Aligns the checkboxes and label to the left */
}

/* Update dropdown-item specifically for sizes to allow three items per row */
.sizes-dropdown .dropdown-item {
    width: 33.33%;
    /* 3 items per row, each takes 1/3 of the width */
    box-sizing: border-box;
    padding-left: 4px;
    display: flex;
    justify-content: flex-start;
}

.sizes-dropdown .dropdown-item-custom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0.25rem 0;
    color: rgb(0, 0, 0);
    gap: 0.5rem;
    margin-left: 14px;
}

/* Custom styles for filter buttons */
.btn-primary {
    background-color: #fff !important;
    /* White background */
    border: 1px solid black !important;
    /* Thick black border */
    color: black !important;
    /* Black text */
    font-weight: bold;
    transition: background-color 0.3s, border-color 0.3s;

}

/* Hover effect for filter buttons */
.btn-primary:hover {
    background-color: #000000 !important;
    /* Black background on hover */
    color: rgb(255, 255, 255) !important;
    /* White text on hover */
    border-color: black !important;
    color: white
        /* Path to the hover icon */

}


.price-icon {
    width: 20px;
    height: 20px;
    transition: content 0.3s ease;
}


@media (max-width: 1200px) and (min-width: 768px) {
    .btn-primary .d-flex {
        flex-direction: column !important;
        justify-content: flex-start !important;
        align-items: flex-start !important;
    }

    .btn-primary {
        padding-left: 4px !important;
        /* Adjust left padding */
    }

    span.ms-2 {
        margin-left: 0 !important;
    }

}



.btn-primary:hover .filter-icon {
    filter: invert(100%);
}


/* Remove default focus outline */
.form-check-input:focus {
    outline: none;
    box-shadow: none;
}

.filter-icon {
    width: 35px;
    height: 35px;

    vertical-align: middle;
}

/* Specific styles for filter buttons */
.btn-filter {
    background-color: #fff;
    border: 1px solid black;
    color: black;
    font-weight: bold;
    transition: background-color 0.3s, border-color 0.3s;
}

.btn-filter:hover {
    background-color: black;
    color: white;
    border-color: black;
}


/* Custom checkbox appearance when checked */
.form-check-input:checked {
    box-shadow: 0 0 10px rgba(0, 123, 255, 0.8);
    /* Increased blue glow around the checkbox */
    border-color: rgba(0, 123, 255, 1);
    /* Transition effect */
    transition: box-shadow 0.5s ease, border-color 0.5s ease;
}

/* Custom focus state for accessibility */
.form-check-input:focus-visible {
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5);
    /* Focus outline */
    border-color: rgba(0, 123, 255, 1);
}

.form-check-input {
    transition: box-shadow 0.5s ease, border-color 0.5s ease !important;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem
}

.dropdown-item:hover,
.dropdown-item:focus,
.dropdown-item:active {
    outline: none !important;
    box-shadow: none !important;
    background-color: inherit !important;
    border-color: inherit !important;
}

.dropdown-menu.show {
    transition: none !important;
}

/* Disable hover effects on mobile devices */
@media (max-width: 768px) {
    .btn-primary:hover {
        background-color: #fff !important;
        /* Maintain default style */
        color: black !important;
        border-color: black !important;
    }

    .btn-primary:active {
        background-color: #000000 !important;
        /* Add flash effect on click */
        color: rgb(255, 255, 255) !important;
        /* White text on click */
    }

    .btn-filter:hover {
        background-color: black;
        color: white;
        border-color: black;
    }

    .btn-primary:hover .filter-icon {
        filter: none;
    }

    .dropdown-item:hover,
    .dropdown-item:focus,
    .dropdown-item:active {
        background-color: inherit !important;
        border-color: inherit !important;
    }
}