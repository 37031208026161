@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');





body,
p,
h1,
h2,
h3,
h4,

h6,

span,
li,
input,
button {
    font-family: "Roboto", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
}

label {
    font-size: small;
}

h5,
a {
    font-family: "Roboto", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
}

.checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.form-check {
    margin-right: 15px;
    margin-bottom: 10px;
}

#sizesSection {
    margin-top: 10px;
}

#sizesTab {
    font-weight: bold;
    margin-top: 20px;
}

@media only screen and (max-width: 768px) {

    /* For all scrollbars */
    body,
    html {
        /* Hides scrollbar in most browsers */
        overflow: -moz-scrollbars-none;
        /* Firefox */
        -ms-overflow-style: none;
        /* Internet Explorer 10+ */
        scrollbar-width: none;
        /* Firefox */
    }

    /* WebKit-based browsers (Chrome, Safari, Edge, etc.) */
    body::-webkit-scrollbar {
        display: none;
        /* Hide scrollbar */
    }
}