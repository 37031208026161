.store-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    background: transparent;
}

.store-container::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(140deg, rgba(255, 255, 255, 0) 0%, var(--store-color, #000) 200%);
    opacity: 0;
    /* Start as invisible */
    transition: opacity 0.5s ease-in-out;
    border-radius: 6px;
    /* Transition opacity */
    z-index: -1;
}

.store-container.hovered::after {
    opacity: 0.6;
    /* Adjust opacity on hover */
}

/* Ensure content does not overlap with the gradient */
.store-container.hovered {
    background-color: transparent;
}

/* Default background for non-hovered state */
.store-container.default-background {
    background: transparent;
}


.size-grid {
    display: grid;
    grid-template-columns: repeat(6, minmax(48px, 1fr));
    /* Responsive grid */
    gap: 6px;
    justify-content: center;
    align-items: center;
    place-items: center;
}



.size-box {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 2px solid gray;
    padding: 8px 6px;
    border-radius: 4px;
    font-size: 0.8rem;
    white-space: nowrap;
    cursor: pointer;
    width: 48px;
    height: 20px;
    box-sizing: border-box;
}

@media (max-width: 1250px) {
    .size-grid {
        grid-template-columns: repeat(5, minmax(48px, 1fr));
        /* Reduce to 5 columns */
    }
}

@media (max-width: 1150) {
    .size-grid {
        grid-template-columns: repeat(4, minmax(48px, 1fr));
        /* Reduce to 4 columns */
    }
}

.separator {
    margin: 0 0;
    color: #333;
    font-size: 0.8rem;
}

.card-info {
    display: flex;
    flex-direction: row;
    height: 100%;
    margin-top: auto;
}

.scrollable-stores {
    max-height: 200px;
    overflow-y: auto;
}

.scrollable-stores .store-container {
    flex-shrink: 0;
    width: 100%;
    height: 80%;
}

.shoe-image-container {
    flex-shrink: 0;
    width: 50%;
    padding-right: 10px;
}

.card-info>div {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: 10px;
}

.card {
    border: 2px solid;
}

.border-right {
    border-right: 1px solid;
}

.border-horizontal {
    border-bottom: 1px solid;
}

@media (max-width: 992px) {
    .border-right {
        border-right: none;
        /* Remove the right border */
        border-bottom: 1px solid;
        /* Add the bottom border */
    }
}

@media (max-width: 768px) {
    .card-body {
        display: flex;
        flex-direction: column;
    }

    .card-info {
        flex-direction: column;
    }

    .shoe-image-container {
        width: 100%;
        padding: 0;
        margin-bottom: 1rem;
    }

    .col-md-12.col-lg-7 {
        border-right: none;
        margin-bottom: 1rem;
    }



    .size-grid {
        grid-template-columns: repeat(5, minmax(48px, 1fr));
    }

    .scrollable-stores {
        max-height: 250px;
    }

    .border-horizontal {
        border-bottom: none;
    }
}